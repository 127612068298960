import React from 'react'
import PropTypes from 'prop-types'
import EditorTable from '@components/editortable'

export const frontmatter = {
  title: 'Editoren',
}

function EditorPage() {
  return <EditorTable />
}

EditorPage.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default EditorPage
