import React, { useEffect, useState, useRef } from 'react'
// import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { DeleteForeverRounded, RotateLeftRounded } from '@material-ui/icons'
import { Box, Button } from '@material-ui/core'

import LoadingSkeleton from '@objects/loadingSkeleton'
import Table from '@objects/table'
import useDialog from '@hooks/useDialog'
import useSnackbar from '@hooks/useSnackbar'
import {
  getEditors,
  postEditor,
  deleteEditor,
  putNewPasswordEditor,
} from '@services/editorservice'

const tableHeader = [
  { id: 'name', label: 'Name' },
  { id: 'email', label: 'Email' },
  { id: 'actions', label: 'Aktionen' },
]

function EditorTable() {
  const isMountedRef = useRef(null)
  const dial = useDialog()
  const [editorList, setEditorList] = useState([])
  const { toggleSnackbar } = useSnackbar()
  const tableActions = [
    {
      title: 'Löschen',
      icon: <DeleteForeverRounded />,
      onClick: (row) => {
        rowDeleteEditor(row.id)
      },
    },
    {
      title: 'Passwort zurücksetzen',
      icon: <RotateLeftRounded />,
      onClick: (row) => {
        dial.openDialog('editorpwreset', {
          onSubmit: (values) => dialogEditorPwReset(row.id, values),
        })
      },
    },
  ]

  useEffect(() => {
    isMountedRef.current = true
    refreshEditorList()

    return () => {
      isMountedRef.current = false
    }
  }, [])

  function refreshEditorList() {
    setEditorList([])
    getEditors()
      .then((editors) => {
        if (isMountedRef.current) {
          setEditorList(
            editors.map((editor) => ({
              id: editor.id,
              hideActions: editor.isSuperuser,
              items: [{ label: editor.name }, { label: editor.email }],
            }))
          )
        }
      })
      .catch((e) => {
        console.error(e)
        toggleSnackbar(
          `Bitte wenden Sie sich an einen Administrator - ${e}`,
          'error'
        )
      })
  }

  function dialogCreateEditor({ name, email, password }) {
    postEditor(name, email, password).then(() => refreshEditorList())
  }

  function dialogEditorPwReset(id, { password }) {
    putNewPasswordEditor(id, password)
  }

  function rowDeleteEditor(id) {
    deleteEditor(id).then(() => refreshEditorList())
  }

  return (
    <Box mt={8}>
      <LoadingSkeleton loading={!!!editorList.length} type="table">
        <Table heads={tableHeader} rows={editorList} actions={tableActions} />
        <Box mb={8} />
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() =>
            dial.openDialog('neweditor', { onSubmit: dialogCreateEditor })
          }
        >
          <FormattedMessage id="button.neweditor" />
        </Button>
      </LoadingSkeleton>
    </Box>
  )
}

EditorTable.propTypes = {}

export default EditorTable
