import apiClient from './basicapi'

function getEditors() {
  return apiClient.get('editoradmin/editors').then((res) => res.data)
}

function postEditor(name, email, password) {
  return apiClient.post('editoradmin/editors', {
    name: name,
    email: email,
    password: password,
  })
}

function deleteEditor(id) {
  return apiClient.delete(`editoradmin/editors/${id}`)
}

function putNewPasswordEditor(id, password) {
  return apiClient.put(`editoradmin/editors/${id}`, {
    newPassword: password,
  })
}

export { getEditors, postEditor, deleteEditor, putNewPasswordEditor }
